$bgWhite: #F8F8FF;
$primary : #35A9F4;
$accent : #FCFC00;
$textColor : #666666;

@font-face {
    font-family: 'WorkSans-Regular';
    src: local('WorkSans'), url(./src/assets/fonts/WorkSans-Regular.ttf)  format('truetype');
}

@font-face {
    font-family: 'WorkSans-SemiBold';
    src: local('WorkSans'), url(./src/assets/fonts/WorkSans-SemiBold.ttf)  format('truetype');
}


  