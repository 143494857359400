.profile-wrapper{
    display: flex;
    padding-top: 40px;
    .profile-wrapper_left{
        width: 200px;
        height: 100vh;
        display: flex;
        border-right: 1px solid #EAEAEA;
        flex-direction: column;
        .profile-wrapper_left_menu{
            p{
                margin-left: 30px;
                margin-top: 20px;
                margin-bottom: 20px;
            }
        }
        .profile-wrapper_left_header{
            align-self: center;
            img{
                width: 140px;
            }
        }
        .profile-wrapper_left_footer{
            margin-top: 50px;
            align-self: center;
            width: 140px;
            .btn_log{
                span{
                    color: #fff!important;
                }
            }
        }
    }
    .profile-wrapper_right{
        flex: 1;
        .profile-wrapper_right_container{
            width: 90%;
            .bg_cover{
                width: 100%;
                height: 120px;
                position: relative;
                background: url('../../assets/images/cover_image.png');
                .avatr{
                    width: 100px;
                    height: 100px;
                    border-radius: 50%;
                    background-color: #E5E4E4;
                    position: absolute;
                    top: 60px;
                    left: 20px;
                }
            }
            .sub_header{
                display: flex;
                margin-left: 140px;
                margin-top: 20px;
                align-items: center;
                justify-content: space-between;
                .sub_header_title{
                    h3{
                        text-align: left;
                        font: normal normal medium 27px/32px Work Sans;
                        letter-spacing: 0px;
                        color: #000000;
                        opacity: 1;
                        font-size: 18px;
                        margin: 0;
                    }
                    span{
                        text-align: left;
                        font-family: WorkSans-Regular;
                        letter-spacing: 0px;
                        color: rgba(#000000, 0.5);
                        font-size: 14px;
                    }
                }
                .sub_header_status{
                    display: flex;
                    align-items: center;
                    .status_value{
                        margin-left: 20px;
                        background-color: #00768E;
                        opacity: 0.29;
                        color: #fff;
                        font-family: Worksans-regular;
                        font-size: 13px;
                        border-radius: 4px;
                        padding: 7px 20px;
                    }
                    span{
                        color: #007891!important;
                        font-family: Worksans-regular;
                    }
                }
            }
            .right_content{
                padding-left: 60px;
                padding-top: 50px;
                h4, h3{
                    text-align: left;
                    font-family: Worksans-regular;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                    font-size: 13px;
                    margin-bottom: 0;
                }
                h3{
                    font-size: 15px;
                    margin-bottom: 30px; 
                }
                span{
                    font-family: Worksans-regular;
                    font-weight: 400;
                    color: rgba(#000000, 0.5);
                }
                .right_content_row{
                    display: flex;
                }
            }
        }
    }
}

.linkk{
    text-align: left;
    font-family: Worksans-regular;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.3;
    font-size: 16px;
    margin-left: 40px;
    margin-top: 20px;
    &:hover{
        color: #006A80!important;
        cursor: pointer;
        opacity: 1;
    }
    span{
        &:hover{
            color: #006A80!important;
            cursor: pointer;
            opacity: 1;
        }
    }
}
._active{
    opacity: 1;
    span{
        color: #006A80!important;
    }
}