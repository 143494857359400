.wrp_container{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    .wrp_left, .wrp_right{
        flex: 1;
    }
    .wrp_left{
        background-color: #007E98;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .img_container{

            img{
                width: 80%;
            }
        }
    }
    .wrp_right{
        padding: 5%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        background: #FFFFFF;
        .right_link{
            align-self: flex-end;
            a{
                text-decoration: none;
                color: #007E98;
            }
        }
        .right_center{
        }
        h3{
            text-align: left;
            letter-spacing: -0.9px;
            color: #111B60;
            opacity: 1;
            font-size: 28px;
            font-family: WorkSans-SemiBold;
        }
        p{
            text-align: left;
            letter-spacing: -0.9px;
            color: #72778F;
            opacity: 1;
            font-size: 14px;
            font-family: WorkSans-Regular;
        }
        .right_form{
            margin-top: 40px;
            display: flex;
            ._form_item{
                display: flex;
                margin-right : 20px;
                flex-direction: column;
            }
        }
        .btn_sbt{
            margin-top: 30px;
        }
    }
}

