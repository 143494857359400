@import 'variables';

.m_container{
  display: flex;
}


.link{
  text-decoration: none;
  color: inherit;
}

.main{
  flex: 1;
  background-size: 20%;
  background-repeat: no-repeat;
  background-position: center;
  @media screen and (max-width: 600px) {
    max-width: 100%;
  }
}

p{
  margin-top: 0;
}
body {
  min-height: 100%!important;
  position: relative;
  font: 'Poppins';
}

._center{
  display: flex;
  justify-content: center;
  align-items: center;
}
h3,h1,h2{
  text-align: left;
  letter-spacing: -1.38px;
  color: #111B60;
  opacity: 1;
  font-family: 'WorkSans-SemiBold';
  font-size: 24px;
}
h1{
  font-size: 36px;
}

._col{
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: flex-start;
  justify-content: flex-start;
}
.l_message{
  padding: 10px 40px;
  text-align: center;
  color: #fff;
  margin-bottom : 20px;
  background-color: rgba(245, 14, 14, 0.4);
}

.g_message{
  background-color: rgba(14, 164, 245, 0.4)!important;
}
