.h_wrp_container{
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    .h_wrp_left, .h_wrp_right{
        flex: 1;
    }
    .h_wrp_left{
        background-color: #FFFFFF;
        height: 100vh;
        display: flex;
        flex-direction: column;
        ._left-10{
            margin-left: 10%;
        }
        .h_img_container{
            margin-left: 5%;
            img{
                height: 120px;
            }
        }
        .hb_img_container{
            position: fixed;
            bottom: -150px;
            left: -100px;
            height: 300px;
            width: 400px;
            background-image: url('../../assets/images/logo_bottom.png');
            background-repeat: no-repeat;
            background-size: cover;
        }
        .header_nb{
            margin-right: 40px;
            margin-top: 10vh;
            align-self: center;
        }
    }
    .h_wrp_right{
        padding: 0 2%;
        height: 100vh;
        overflow-y: scroll;
        display: flex;
        align-items: center;
        .right_form{
            display: flex;
            ._form_item{
                display: flex;
                margin-right : 20px;
                flex-direction: column;
            }
        }
    }
}
