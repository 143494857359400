@import 'variables';


input, textarea,select{
    background: #EEF4FE 0% 0% no-repeat padding-box;
    box-shadow: 8px 15px 24px #363E9300;
    border-radius: 18px;
    opacity: 1;
    outline: none;
    border: none;
    padding: 9px 10px;
    font-size: 12px;
    letter-spacing: -0.38px;
    color: #000000;
    font-family: 'WorkSans-Regular';
    margin-top: 10px;
}
select{
    padding: 9px 20px;
}

input[type=radio] {
    accent-color: #046E83;
 }

label{
    letter-spacing: -0.38px;
    color: #111B60!important;
    font-size: 14px;
    opacity: 1;
    font-family: 'WorkSans-SemiBold',
}

span{
    letter-spacing: -0.38px;
    color: #72778F!important;
    font-size: 13px;
    opacity: 1;
    font-family: 'WorkSans-SemiBold',
}

p{
    letter-spacing: -0.38px;
    color: #72778F!important;
    font-size: 12px;
    opacity: 1;
    font-family: 'WorkSans-Regular',
}

button{
    outline: none;
    border: none;
    cursor: pointer;
    background: transparent linear-gradient(284deg, #007E97 0%, #007E97 57%, #076376 100%) 0% 0% no-repeat padding-box;
    border-radius: 18px;
    opacity: 1;
    color: #FFF;
    padding: 10px 25%;
    &:disabled{
        opacity: 0.4;
        cursor: default;
    }
}

.input-container{
    display: flex;
    align-items: center;
    justify-content: center;
    span{
        margin-left: 5px;
        margin-top: 9px;
        text-align: left;
        letter-spacing: -0.38px;
        color: #72778F;
        opacity: 1;
        font-size: 12px;
        font-family: 'WorkSans-Regular';
    }
}

._max-width{
    width: 100%;
}