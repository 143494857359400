.f_wrap{
    background: transparent linear-gradient(307deg, #007E97 0%, #007E97 57%, #076376 100%) 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    align-items: center;
    .f_center{
        display: flex;
        width: 400px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img{
            width: 60%;
        }
        h1{
            font-size: 24px;
            margin-top: 0px;
            text-align: center;
            color: #FFF;
            margin-bottom: 20px;
            font-family: WorkSans-Regular;
            opacity: 0.8;
            font-weight: 400;
        }
        h2{
            font-size: 24px;
            text-align: center;
            color: #FFF;
            margin-bottom: 40px;
            font-family: WorkSans-SemiBold;
        }
    }
}